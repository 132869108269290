const URL = {
    LOGIN: "/login/",
    REGISTER: "/register/",
    PASSWORD_RESET: "/reset-password/",
    VERIFY_PASSWORD_RESET: "/password-reset/:uid/:token/",
    VERIFY_EMAIL: "/verify-email/:key/",
    LOCATION_CONFIRM: "/location-confirm/",
    DASHBOARD: "/dashboard/",
    PROFILE: "/profile/",
    ACCOUNT: "/account/",
    RACES: "/races/",
    UPCOMING_RACES: "/races/upcoming/",
    PAST_RACES: "/races/past/",
    RACE_IMPORT: "/races/import/",
    RACE_ADD: "/races/add/",
    DOCUMENTS: "/documents/",
    OFFERS: "/offers/",
    REPORTS: "/race-calendar-wizard/",
    SPONSORS: "/sponsor-finder/",
    LOGOUT: "/logout/",
    MEMBERS: "/purchase/",
    MEMBERS_JOIN: "/upgrade/pro/",
    LISTING_UPGRADE: "/upgrade/premium",
    LISTING_CREDITS: "/purchase/listing-credits/",
    QUOTES: "/quotes/",
    FORBIDDEN: "/forbidden/",
    BUDGET_BUILDER: "/budget-builder/",
    BUSINESS_DASHBOARD: "/business/",
    BUSINESS_LISTINGS: "/business/listings/",
    BUSINESS_LISTINGS_ADD: "/business/listings/add/",
    BUSINESS_QUOTES: "/business/quotes/",
    BUSINESS_BADGES: "/business/badges/",
    ADD_REVIEW: "/reviews/",
};

export default URL;
