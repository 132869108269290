import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import API_URL from "app/constants/api_urls";
import {
    BudgetDefaultsType,
    BudgetEventInfoType,
    BudgetItemType,
    BudgetType,
} from "app/types/budget/budget.types";
import API from "app/utils/api/axios";
import { QUERY_KEYS } from "../queries/constants";

const createBudget = async (racePk: number, defaults: BudgetDefaultsType): Promise<BudgetType> => {
    const res = await API.post<BudgetType>(`${API_URL.RACES}${racePk}/budget/add/`, defaults);

    return res.data;
};

const useCreateBudget = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        BudgetType,
        AxiosError<{ message: string }>,
        { racePk: number; defaults: BudgetDefaultsType }
    >({
        mutationFn: ({ racePk, defaults }) => createBudget(racePk, defaults),
        onSuccess: (data, { racePk }) => {
            queryClient.setQueryData<BudgetType>([QUERY_KEYS.BUDGET, racePk], data);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

const updateBudgetItem = async (
    racePk: number,
    item: Partial<BudgetItemType>
): Promise<BudgetType> => {
    const res = await API.patch<BudgetType>(`${API_URL.RACES}${racePk}/budget/update/`, {
        items: [item],
    });

    return res.data;
};

const useUpdateBudgetItem = ({
    onSuccess,
    onError,
}: {
    onSuccess?: ({ item }: { item: number | undefined }) => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        BudgetType,
        AxiosError<{ message: string }>,
        { racePk: number; item: Partial<BudgetItemType> }
    >({
        mutationFn: ({ racePk, item }) => updateBudgetItem(racePk, item),
        onSuccess: (data, { racePk, item }) => {
            queryClient.setQueryData<BudgetType>([QUERY_KEYS.BUDGET, racePk], data);

            if (onSuccess) {
                onSuccess({ item: item.pk });
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

const updateBudgetEvent = async (
    budgetEventPk: number,
    nodes: Array<Partial<BudgetEventInfoType["nodes"][0]>>
): Promise<BudgetType> => {
    const res = await API.patch<BudgetType>(
        `${API_URL.BUDGET_EVENTS}${budgetEventPk}/nodes/update/`,
        {
            nodes,
        }
    );

    return res.data;
};

const useUpdateBudgetEvent = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        BudgetType,
        AxiosError<{ message: string }>,
        {
            budgetEventPk: number;
            budgetRacePk: number;
            nodes: Array<Partial<BudgetEventInfoType["nodes"][0]>>;
        }
    >({
        mutationFn: ({ budgetEventPk, budgetRacePk, nodes }) =>
            updateBudgetEvent(budgetEventPk, nodes),

        onSuccess: (data, { budgetRacePk }) => {
            queryClient.setQueryData<BudgetType>([QUERY_KEYS.BUDGET, budgetRacePk], data);

            if (onSuccess) {
                onSuccess();
            }
        },

        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

export { useUpdateBudgetItem, useUpdateBudgetEvent, useCreateBudget };
