/* eslint-disable react/no-danger */

import { Button, Checkbox, Modal, Typography } from "antd";
import { useExportBudgetData } from "app/hooks/useExportBudgetData";
import { BudgetType } from "app/types/budget/budget.types";
import "./index.scss";
import { useState } from "react";

const export_types = [
    { label: "MS Excel", format: "xlsx", disabled: false },
    {
        label: "GSheets",
        format: "gsheets",
        disabled: true,
    },
    {
        label: "PDF File",
        format: "pdf",
        disabled: true,
    },
];

const ExportBudgetModal = ({
    budget,
    isVisible,
    closeModalHandler,
}: {
    budget: BudgetType | undefined;
    isVisible: boolean;
    closeModalHandler: () => void;
}) => {
    const exportData = useExportBudgetData();
    const [selectedType, setSelectedType] = useState("xlsx");
    const [includeHeadings, setIncludeHeadings] = useState(true);
    const [includeRegistered, setIncludeRegistered] = useState(true);

    return (
        <Modal
            open={isVisible}
            title="Export Budget"
            onCancel={closeModalHandler}
            width={480}
            className="export-budget-modal"
            destroyOnClose
            footer={null}
            centered
        >
            <Typography.Text>
                Pick from the options below to export your budget in your preferred format.
            </Typography.Text>

            <div className="export-budget-modal__types-selector">
                {export_types.map((type) => (
                    <button
                        className={`export-budget-modal__type-option ${
                            selectedType === type.format ? "active" : ""
                        }`}
                        onClick={() => setSelectedType(type.format)}
                        type="button"
                        disabled={type.disabled}
                    >
                        <img src={`/assets/documents/${type.format}.png`} alt={type.label} />
                        {type.label}
                    </button>
                ))}
            </div>

            <div className="export-budget-modal__additional">
                <Typography.Text className="export-budget-modal__additional-title">
                    Additional parameters:
                </Typography.Text>

                <div className="export-budget-modal__additional-item">
                    <Checkbox
                        checked={includeHeadings}
                        onChange={(e) => setIncludeHeadings(e.target.checked)}
                    />

                    <div>
                        <Typography.Text>Include item group headings</Typography.Text>
                        <Typography.Text>
                            E.g. Supplies & Equipment, Race Timing etc
                        </Typography.Text>
                    </div>
                </div>

                <div className="export-budget-modal__additional-item">
                    <Checkbox
                        checked={includeRegistered}
                        onChange={(e) => setIncludeRegistered(e.target.checked)}
                    />

                    <div>
                        <Typography.Text>Include “Registered” totals</Typography.Text>
                        <Typography.Text>
                            This will include numbers based on registered participants
                        </Typography.Text>
                    </div>
                </div>
            </div>

            <Button
                type="primary"
                className="export-budget-modal__cta"
                onClick={() => exportData({ data: budget, includeHeadings, includeRegistered })}
            >
                <span>Export</span>
            </Button>
        </Modal>
    );
};

export default ExportBudgetModal;
