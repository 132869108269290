import userSlice from "app/store/user/user.slice";
import { BudgetItemType, BudgetType } from "app/types/budget/budget.types";
import { formatCurrency } from "app/utils/helpers/format_currency";
import { formatNumber } from "app/utils/helpers/format_number";
import XLSX from "xlsx";

export const useExportBudgetData = () => {
    const { user } = userSlice();

    return async ({
        data,
        includeHeadings,
        includeRegistered,
    }: {
        data: BudgetType | undefined;
        includeHeadings: boolean;
        includeRegistered: boolean;
    }) => {
        if (data?.items) {
            /* flatten objects */
            const items = data.items.map((row) => ({
                ...(includeHeadings && {
                    group: {
                        label: "Group",
                        value: row.category?.group,
                    },
                }),

                name: { label: "Name", value: row.category?.name as string },
                event: {
                    label: "Event",
                    value: row.event === null ? "All Events" : row.event?.name,
                },
                price: { label: "Price", value: formatCurrency(row.price, user?.location?.ccy, 0) },
                quantity_type: {
                    label: "Quantity Type",
                    value: row.quantity_type.charAt(0).toUpperCase() + row.quantity_type.slice(1),
                },
                quantity_proj: { label: "Quantity (Proj)", value: formatNumber(row.quantity_proj) },
                total_proj: { label: "Total (Proj)", value: formatNumber(row.total_proj) },

                ...(includeRegistered && {
                    quantity_reg: {
                        label: "Quantity (Reg)",
                        value: formatNumber(row.quantity_curr),
                    },
                    total_reg: { label: "Total (Reg)", value: formatNumber(row.total_curr) },
                }),
            }));

            const rows = items.map((item) => {
                const transformedItem: {
                    [key: string]: any;
                } = {};

                // Iterate over the keys of each item and set key-value pairs
                Object.entries(item).forEach(([key, valueObj]) => {
                    transformedItem[key] = valueObj.value; // Assign value of each key
                });

                return transformedItem;
            });

            const labelsSet: Set<string> = new Set();
            items.forEach((item) => {
                // Use Object.values to get all label-value pairs and extract the labels
                Object.values(item).forEach((valueObj) => {
                    labelsSet.add(valueObj.label); // Add label to the Set to ensure uniqueness
                });
            });
            const labels = Array.from(labelsSet);

            /* generate worksheet and workbook */
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Items");

            /* fix headers */
            XLSX.utils.sheet_add_aoa(worksheet, [labels], { origin: "A1" });

            /* calculate column widths */
            // The minimum numbers are the letter count of column headers (e.g. name = 4)
            const colWidths = labels.map((label) => {
                const headerWidth = label.length;
                const maxContentWidth = Math.max(
                    ...rows.map((row) => {
                        const key = label.toLowerCase().replace(/\s|\(|\)/g, "_");
                        return row[key] ? row[key].toString().length : 0;
                    })
                );
                return { wch: Math.max(headerWidth, maxContentWidth) };
            });

            worksheet["!cols"] = colWidths;

            /* create an XLSX file */
            XLSX.writeFile(workbook, "budget.xlsx", { compression: true });
        }
    };
};
