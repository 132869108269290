import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import BlurredText from "app/components/elements/wrappers/blurred_text";
import URL from "app/constants/route_urls";

const ObscuredQuoteWrapper = ({
    text,
    fontSize = "14px",
    blurAmount = 2.5,
    color = "#090b0b",
    font = "Basier Circle",
    fontWeight = "400",
    padding = 5,
    listing,
    textHidden = false,
}: {
    text: string;
    fontSize?: string;
    fontWeight?: string;
    font?: string;
    color?: string;
    blurAmount?: number;
    padding?: number;
    listing: any;
    textHidden?: boolean;
}) => {
    const navigate = useNavigate();
    const isPremiumListing = listing?.is_premium;

    if (textHidden) return "-";
    if (isPremiumListing) return text;

    return (
        <Tooltip
            title={
                isPremiumListing ? undefined : (
                    <span>
                        This information is only available to Premium listings.{" "}
                        <span
                            role="link"
                            aria-hidden="true"
                            className="link-inline"
                            onClick={() => {
                                navigate(`${URL.LISTING_UPGRADE}/${listing?.uuid}/`, {
                                    state: { listing },
                                });
                            }}
                        >
                            Upgrade to Premium
                        </span>
                    </span>
                )
            }
        >
            <span className="vendor-quote-requests__text-wrapper">
                <BlurredText
                    text={text}
                    blurAmount={blurAmount}
                    color={color}
                    font={font}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    padding={padding}
                />
            </span>
        </Tooltip>
    );
};

export default ObscuredQuoteWrapper;
