import { Button, Col, InputNumber, Modal, Row, Switch, Tooltip, Typography } from "antd";
import LoadingIndicator from "app/components/elements/loading_indicator/loading_indicator";
import racesSlice from "app/store/races/races.slice";
import { useGetBudgetDefaults } from "app/utils/api/queries/budget.query";
import "./index.scss";
import { formatCurrency } from "app/utils/helpers/format_currency";
import userSlice from "app/store/user/user.slice";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BudgetDefaultsType } from "app/types/budget/budget.types";
import { useNavigate } from "react-router-dom";
import { useCreateBudget } from "app/utils/api/mutations/budget.mutation";
import { _isEmpty } from "app/utils/helpers";
import { qty_type_icons } from "../budget_sheet/budget_columns";

const CreateBudgetModal = () => {
    const { currentRace } = racesSlice((state) => state);
    const { data: defaults, isLoading } = useGetBudgetDefaults(currentRace.pk!, {
        enabled: !!currentRace.pk,
    });
    const { user } = userSlice((state) => state);
    const navigate = useNavigate();

    const {
        handleSubmit,
        watch,
        reset,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<BudgetDefaultsType>({});

    const { mutate: createBudget, isPending } = useCreateBudget({});

    const onSubmit: SubmitHandler<BudgetDefaultsType> = (data: typeof defaults) => {
        if (data?.items) {
            const prepareData = {
                items: data.items
                    .filter((i) => i.virtual_included)
                    .map(({ virtual_included, ...rest }) => rest),
            };

            createBudget({ defaults: prepareData, racePk: currentRace.pk! });
        }
    };

    useEffect(() => {
        reset({ items: defaults?.items.map((item) => ({ ...item, virtual_included: true })) });
    }, [defaults, reset]);

    const values = getValues();

    const groups = [
        ...new Map(
            values?.items?.map((i) => [JSON.stringify(i.category.group), i.category.group])
        ).values(),
    ];

    return (
        <Modal
            open
            title="Budget Launcher"
            width={480}
            className="create-budget-modal"
            centered={false}
            destroyOnClose
            closable={false}
            footer={null}
        >
            {isLoading || _isEmpty(groups) ? (
                <LoadingIndicator />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography.Text>
                        Pick the items you would like to include in your budget. You will be able to
                        add more items once your base budget has been created.
                    </Typography.Text>

                    <div className="create-budget-modal__head">
                        <Row>
                            <Col span={12} />
                            <Col span={3}>
                                <span className="create-budget-modal__row-title">Include</span>
                            </Col>
                            <Col span={2} />
                            <Col span={6}>
                                <span className="create-budget-modal__row-title">Price</span>
                            </Col>
                            <Col span={1} />
                        </Row>
                    </div>

                    {groups?.map((group) => (
                        <div className="create-budget-modal__group" key={group.name}>
                            <Row>
                                <Col span={12}>
                                    <span className="create-budget-modal__group-title">
                                        {group.name}
                                    </span>
                                </Col>
                                <Col span={3} />
                                <Col span={2} />
                                <Col span={6} />
                                <Col span={1} />
                            </Row>

                            {values?.items
                                ?.filter((item) => item.category.group.pk === group.pk)
                                .map((i) => {
                                    const index = values?.items.findIndex(
                                        (search) => search.category.pk === i.category.pk
                                    );

                                    const disabled = !watch(`items.${index}.virtual_included`);

                                    return (
                                        <Row key={i.category.pk}>
                                            <Col span={12}>{i.category.name}</Col>
                                            <Col span={3}>
                                                <Switch
                                                    size="small"
                                                    value={watch(`items.${index}.virtual_included`)}
                                                    onChange={(checked) =>
                                                        setValue(
                                                            `items.${index}.virtual_included`,
                                                            checked
                                                        )
                                                    }
                                                    className="create-budget-modal__switch"
                                                />
                                            </Col>
                                            <Col span={2} />
                                            <Col span={6}>
                                                <InputNumber
                                                    controls={false}
                                                    prefix={user?.location?.ccy_symbol}
                                                    size="small"
                                                    value={watch(`items.${index}.price`)}
                                                    onChange={(value) => {
                                                        if (value === null || value === 0) return;

                                                        setValue(`items.${index}.price`, value);
                                                    }}
                                                    disabled={disabled}
                                                    className="create-budget-modal__price-input"
                                                />
                                            </Col>
                                            <Col span={1}>
                                                <Tooltip
                                                    title={
                                                        disabled
                                                            ? undefined
                                                            : `Per ${watch(`items.${index}.quantity_type`)}`
                                                    }
                                                >
                                                    <span
                                                        className={`budget-sheet__qty-type-cell ${disabled ? "disabled" : ""}`}
                                                    >
                                                        {
                                                            qty_type_icons[
                                                                watch(
                                                                    `items.${index}.quantity_type`
                                                                )
                                                            ]
                                                        }
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </div>
                    ))}
                    <div className="budget__modal-actions">
                        <Button
                            className="responsive-cta budget__modal-cta"
                            type="primary"
                            size="middle"
                            htmlType="submit"
                            loading={isPending}
                        >
                            Create budget
                        </Button>

                        <Button
                            className="responsive-cta ant-btn-primary-outline budget__modal-cta"
                            type="default"
                            size="middle"
                            onClick={() => navigate(-1)}
                            disabled={isPending}
                        >
                            Back
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default CreateBudgetModal;
