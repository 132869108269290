const API_URL = {
    USER_DATA: "/api/auth/user/",
    USER_APPS: "/api/apps/",
    STRIPE_KEY: "/api/stripe/pk/",
    SUBSCRIBE: "/api/users/subscribe/",
    BUY_CREDITS: "/api/listing_credits/buy/",
    CUSTOMER_PORTAL: "/api/users/customer_portal/",
    LOGIN_USER: "/api/auth/login/",
    LOGIN_GOOGLE: "/api/auth/google/",
    LOGIN_FACEBOOK: "/api/auth/facebook/",
    REGISTER_USER: "/api/auth/register/",
    PASSWORD_RESET: "/api/auth/password/reset/",
    PASSWORD_RESET_CONFIRM: "/api/auth/password/reset/confirm/",
    VERIFY_EMAIL: "/api/auth/verify-email/",
    LOGOUT_USER: "/api/auth/logout/",
    UPDATE_USER_PROFILE: "/api/users/profile/update/",
    USER_LOCATIONS: "/api/countries/",
    DOCUMENTS: "/api/documents/",
    RACES: "/api/races/",
    RACE_DETAILS: "/api/races/",
    ADD_RACE: "/api/races/add/",
    IMPORT_RACE: "/api/races/import/",
    DELETE_RACE: "/api/races/",
    MARKETER_REPORT: "/api/races/",
    MARKETER_LIST_RACE: "/api/races/",
    RACE_FORM_DATA: "/api/races/form_data/",
    SPONSORS: "/api/races/",
    SPONSORS_BROKEN_LINK: "/api/sponsors/",
    VENDOR_OFFERS: "/api/offers/",
    CLAIM_VENDOR_OFFER: "/api/offers/",
    QUOTES: "/api/quotes/",
    BUDGETS: "/api/budgets/",
    BUDGETS_FORM_DATA: "/api/budgets/form_data/",
    BUDGET_EVENTS: "/api/budget_events/",
    BUSINESS_LISTINGS: "/api/listings/",
    BUSINESS_LISTINGS_ADD: "/api/listings/add/",
    BUSINESS_LISTINGS_EDIT: "/api/listings",
    BUSINESS_LISTINGS_FORM_DATA: "/api/listings/form_data/",
    BUSINESS_QUOTE_REQUESTS: "/api/listings",
    BUSINESS_QUOTE: "/api/quotes",
    UPGRADE_LISTING: "/api/listings",
    FREE_TRIALS: "/api/free_trials/",
    ADD_FREE_TRIAL: "/api/free_trials/add/",
    REVIEWS: "/api/reviews/",
};

export default API_URL;
